import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router/router.js'
import moment from 'moment'
// import createPersistedState from 'vuex-persistedstate'
// moduli specifici per le varie entity
// import UserModule from '@/store/user-module'

Vue.use(Vuex)

// ------------------------------------------------------------------------------------------------------
// --- STATE --------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
const state = {
	state: '',
	// versione UI (ricavata da package e autoincrementata tramite yarn version)
	uiName: JSON.parse(unescape(process.env.PACKAGE_JSON || '%7B"name"%3A0%7D')).name,
	uiVersion: JSON.parse(unescape(process.env.PACKAGE_JSON || '%7B"version"%3A0.0%7D')).version,
	uiBetaTag: JSON.parse(unescape(process.env.PACKAGE_JSON || '%7B"beta"%3A0%7D')).beta,
	// informazioni di versione delle api 
	apiVersion: {
		AppVersion: '', // versione api ricavata dal tag git scritto da questa ui ad ogni build (utile per capire se/quanto le api sono indietro)
		AppAssemblyVersion: '', // versione api ricavata dal numero di versione .net (sostanzialmente inutile)
		AppBranch: '', // branch git attivo al momento della compilazione, molto utile!
		AppDate: '', // data di compilazione della api
	},
	token: null,
	keepAliveIntervalId: null,
	usersStatus: [],
	loading: false,
	// struttura di setup globale
	setup: {},
	setupErrorsDecodifiche: '',
	// keyvalue dell'utente corrente
	keyvalues: [],
	// utente corrente
	user: {},
	// struttura menu
	menu: [],
	// aziende
	aziende: [],
	// raggruppa tutte le sottotabelle di decodifica
	decodifiche: {},
	// elenco persone
	persone: [],
	// persona correntemente in edit
	personaCorrente: {},
	// elenco competenze
	competenze: [],
	// audits
	audits: [],
	// valutazioni
	valutazioni: [],
	// formazione
	// formazione: [],

	// gruppi utenti
	gruppiUtenti: [],
	// statistiche per dash
	stat: {},
	// calendario corsi per dash
	calendarioCorsi: {},
	// colori per gli eventi del calendario
	calendarioColori:  ['red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green', 'lime', 'yellow', 'amber', 'orange', 'deep-orange', 'brown', 'blue-grey', 'grey'],
	// ----------------
	// se true il login è ok
	isLoggedIn: false,
	// contatore fallimenti di keepalive
	keepAliveFail: 0,
	// rende visibili gli elementi di debug in test e develop mode
	viewDebugElements: false,
	// se true forza gTransalte ad aggiungere un icona di identificazione visiva a tutti gli elmenti traducibili
	viewTranslatableElements: false,
	// visualizza section/key al posto del valore (server per identificare rapidamente la chiave da tradurre)
	viewKeyTranslatableElements: false,
	// default visualizzazione campi edit
	tipologiaCampiEdit: {
		filled: true,
		solo: false,
		outlined: false,
		dense: true,
	},
	// visluazlizzazione compatta (dense sulle v-row in sostanza)
	compactVisualization: true,
	// modalita apertura numerdi di cellulare (defautl = false = dialer, true = whatsapp)
	cellularOpenMode: false,
	personeExitAfterSave: false,
	// ---------------- stati utility senza getter
	// contatore incrementale negativo per gli id temporanei
	negativeIncrementalId: 0
}


// ------------------------------------------------------------------------------------------------------
// --- GETTERES -----------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
const getters = {
	// path delle immagini delle persone (tiene conto delle variazioni tra sviluppo e produzione)
	imagesPathPerson: () => process.env.VUE_APP_WEBAPIROOT + 'public/person/',
	uiVersion: state => state.uiVersion,
	uiVersionBetaTag: state => state.uiBetaTag,
	apiVersion: state => state.apiVersion,
	isLoggedIn: state => state.isLoggedIn,
	loading: state => state.loading,
	keyvalues: state => state.keyvalues,
	setup: state => state.setup,
	setupErrorsDecodifiche: state => state.setupErrorsDecodifiche,
	aziende: state => state.aziende,
	
	currentUser: state => state.user,
	decodifiche: state => state.decodifiche,
	appMenu: state => state.menu,
	persone: state => state.persone,
	personaCorrente: state => state.personaCorrente,
	competenze: state => state.competenze,
	audits: state => state.audits,
	valutazioni: state => state.valutazioni,
	// formazione: state => state.formazione,
	gruppiUtenti: state => state.gruppiUtenti,
	stat: state => state.stat,
	calendarioCorsi: state => state.calendarioCorsi,
	calendarioColori: state => state.calendarioColori,
	viewDebugElements: state => state.viewDebugElements,
	viewTranslatableElements: state => state.viewTranslatableElements,
	viewKeyTranslatableElements: state => state.viewKeyTranslatableElements,
	tipologiaCampiEdit: state => state.tipologiaCampiEdit,
	compactVisualization: state => state.compactVisualization,
	cellularOpenMode: state => state.cellularOpenMode,
	personeExitAfterSave: state => state.personeExitAfterSave,
	usersStatus: state => state.usersStatus
}

// ------------------------------------------------------------------------------------------------------
// --- MUTATIONS ----------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
const mutations = {
	SET_TOKEN(state, token) {
		state.token = token
	},
	SET_VERSION(state, data) {
		state.apiVersion = data
	},
	SET_SETUP_ERRORS(state, data) {
		state.setupErrorsDecodifiche = data
	},
	SET_UTENTE(state, user) {
		state.user = user
	},
	SET_MENU(state, menu) {
		state.menu = menu
	},
	SET_KEYVALUES(state, keyvalues) {
		state.keyvalues = keyvalues
	},
	SET_SETUP(state, setup) {
		state.setup = setup
	},
	SET_AZIENDE(state, aziende) {
		state.aziende = aziende
	},

	// carica lista 
	SET_PERSONE(state, persone) {
		state.persone = persone
	},
	// aggiunge nuova persona
	ADD_PERSONA(state, persona) {
		state.persone.push(persona)
	},
	// imposta la persona di edit corrente
	EDIT_PERSONA(state, persona) {
		state.personaCorrente = persona
	},
	// elimina persona (!! attenzione: accetta l'indice dell'elemento nell'array !!)
	DELETE_PERSONA(state, index) {
		state.persone.splice(index, 1)
	},
	// modifica persona (!! attenzione: l'oggetto deve contendere l'indice dell'elemento nell'array !!)
	UPDATE_PERSONA(state, persona) {
		state.persone.splice(persona.Index, 1, persona)
	},
	// salva il blocco delle competenze
	SET_COMPETENZE(state, competenze) {
		state.competenze = competenze
	},
	// salva il blocco degli audits
	SET_AUDITS(state, audits) {
		state.audits = audits
	},
	// salva il blocco delle valutazioni
	SET_VALUTAZIONI(state, valutazioni) {
		state.valutazioni = valutazioni
	},
	/*
	// salva il blocco della formazione
	SET_FORMAZIONE(state, formazione) {
		state.formazione = formazione
	},
	*/
	// salva il blocco delle decodifiche 
	SET_DECODIFICHE(state, decodifiche) {
		state.decodifiche = decodifiche
	},
	// aggiunge una  decodifica
	MODIFY_DECODIFICA_ITEM(state, item) {
		if(item.op === 'add') {
			state.decodifiche[item.table].push(item.value)
		} else if(item.op === 'update') {
			state.decodifiche[item.table].splice(item.index, 1, item.value)
		} else {
			// op non prevista
		}
	},
	// salva il blocco gruppiUtenti
	SET_GRUPPIUTENTI(state, gruppiUtenti) {
		state.gruppiUtenti = gruppiUtenti
	},
	// salva il blocco delle statistiche per la dashboard
	SET_STAT(state, data) {
		state.stat = data
	},
	// salva il blocco del calendario corsi per la dashbaord
	SET_CALENDARIO_CORSI(state, data) {
		state.calendarioCorsi = data
	},
	// aggiorna i job
	UPDATE_JOBS(state, jobs) {
		state.decodifiche.Jobs = jobs
	},
	// aggiorna il link tra job e competenze
	UPDATE_JOBS_COMPETENZE(state, jobsCompetenze) {
		state.decodifiche.JobsCompetenze = jobsCompetenze
	},
	// barbatrucco: carica un blocco con i livelli delle competenze rioprganizzati per agebvolare il bind
	SET_DECODIFICHE_LIV_COMP_BIND(state, liv_comp_bind) {
		state.decodifiche.LivelliCompetenzePerBind = liv_comp_bind
	},
	//
	// prelogin: attiva il flag di loading che poi verrà disattivato a login completato ( o fallito)
	INIT(state) {
		state.loading = true
	},
	INIT_FAILED(state) {
		state.loading = false
	},
	// attiva  login
	LOGIN(state, keepAliveIntervalId) {
		state.loading = false
		state.isLoggedIn = true
		state.keepAliveIntervalId = keepAliveIntervalId
	},
	// logout e cancellazione stati
	LOGOUT(state) {
		clearInterval(state.keepAliveIntervalId)
		state.isLoggedIn = false
		state.token = null
		state.user = {}
		state.menu = []
	},
	// scatenato al cambio azienda
	CAMBIO_AZIENDA(state) {
		clearInterval(state.keepAliveIntervalId)
	},
	KEEPALIVE_OK(state, usersStatus) {
		state.keepAliveFail = 0
		state.usersStatus = usersStatus
	},
	KEEPALIVE_FAIL(state) {
		state.keepAliveFail = state.keepAliveFail + 1
	},
	TOGGLE_DEBUG_ELEMENTS(state) {
		state.viewDebugElements = !state.viewDebugElements
	},
	TOGGLE_SHOW_TRANSLATABLE_ELEMENTS(state) {
		state.viewTranslatableElements = !state.viewTranslatableElements
	},
	TOGGLE_SHOW_KEY_TRANSLATABLE_ELEMENTS(state) {
		state.viewKeyTranslatableElements = !state.viewKeyTranslatableElements
	},
	INCREMENT_NEGATIVE_ID(state) {
		state.negativeIncrementalId--
	},
	SET_TIPOLOGIA_CAMPI_EDIT(state, value) {
		state.tipologiaCampiEdit = value
	},
	TOGGLE_COMPACT_VISUALIZATION(state) {
		state.compactVisualization = !state.compactVisualization
	},
	TOGGLE_CELLULAR_OPEN_MODE(state) {
		state.cellularOpenMode = !state.cellularOpenMode
	},
	TOGGLE_PERSONE_EXIT_AFTER_SAVE(state) {
		state.personeExitAfterSave = !state.personeExitAfterSave
	},
}


// ------------------------------------------------------------------------------------------------------
// --- ACTIONS ------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
const actions = {
	// gestione keepalive
	keepAlive(context) {
		if (context.state.isLoggedIn) {
			Vue.axios.post('/setup/keepalive').then(response => {
				// console.debug('## KeepAlive ok', response)
				if (response.data.Ok) {
					context.commit('KEEPALIVE_OK', response.data.UsersStatus)
				} else {
					console.error('#### KeepAlive Session KO')
					actions.logout(context)
					// actions.messageError(context, 'Sessione scaduta!')
				}
			}).catch(error => {
				console.error('#### KeepAlive KO', error)
				context.commit('KEEPALIVE_FAIL')
				// consentiamo due solo fail, poi scatta il logout
				if (context.state.keepAliveFail > 2) {
					// location.reload()
					actions.logout(context)
					// actions.messageError(context, 'Connessione persa!')
				}
			})
		}
	},
	// esegue il login contattando l'apposita api
	login(context, data) {
		return new Promise((resolve, reject) => {
			context.commit('INIT')
			Vue.axios.defaults.headers.common['Authorization'] = ''
			Vue.axios.post('/token/login', data).then(response => {
				// login ok, abbiamo il token -> impostato come default axios
				Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.Jwt
				context.commit('SET_VERSION', response.data.Version)
				// carica tutti i dati possibili
				actions.init(context).then(() => {
					resolve()
				}).catch(error => {
					reject(error)
				})
			}).catch(error => {
				context.commit('INIT_FAILED')
				console.error('error in post/token/login\n', error)
				reject(error)
			})
		})
	},
	// blocco primario di init in cui effettua il caricamento di tutti i dati postlogin
	// viene chiamato anche in caso di cambio azienda
	init(context) {
		return new Promise((resolve, reject) => {
			Promise.all([
				Vue.axios.get('setup/currentValues'),
				Vue.axios.post('RisorseUmane/Persone/List', {'LoadDeleted':false}),
				Vue.axios.post('RisorseUmane/Competenze/List'),
				Vue.axios.post('RisorseUmane/Valutazioni/List'),
				Vue.axios.post('RisorseUmane/Audits/List'),
			]).then(function([responseSetup, responsePersone, responseCompetenze, responseValutazioni, responseAudits]) {
				// controllo versione con il server e reload se necessario
				// actions.testClientVersion(context, response.data.clientVersion)
				// salviamo i dati utente
				var isGuest = responseSetup.data.User.IsGuest
				
				context.commit('SET_SETUP_ERRORS', responseSetup.data.DecodificheError)
				//
				context.commit('SET_UTENTE', responseSetup.data.User)
				context.commit('SET_KEYVALUES', responseSetup.data.Keyvalues)
				context.commit('SET_SETUP', responseSetup.data.Setup)
				// struttura del menu
				context.commit('SET_MENU', responseSetup.data.Menu)
				// carica elenco persone
				context.commit('SET_PERSONE', responsePersone.data)

				if(isGuest) {
					// per gli utenti guest carica la propria struttura persona
					context.commit('EDIT_PERSONA', responsePersone.data[0])
				}

				// context.commit('SET_PERSONE', responseSetup.data.Persone)
				// context.commit('SET_COMPETENZE', responseSetup.data.Competenze)
				context.commit('SET_COMPETENZE', responseCompetenze.data)
				// context.commit('SET_AUDITS', responseSetup.data.Audits)
				context.commit('SET_AUDITS', responseAudits.data)
				context.commit('SET_VALUTAZIONI', responseValutazioni.data)
				// context.commit('SET_VALUTAZIONI', responseSetup.data.Valutazioni)
				// togliere! context.commit('SET_FORMAZIONE', responseSetup.data.Formazione)
				context.commit('SET_DECODIFICHE', responseSetup.data.Decodifiche)
				actions.decodificaLivelliCompetenzaPerBind(context)
				// strutture specifiche per admin
				context.commit('SET_GRUPPIUTENTI', responseSetup.data.Gruppi)
				// carica le traduzioni per il client
				context.commit('translation/UPDATE_TRANSLATIONS', responseSetup.data.Translations)
				// carica tutte le traduzioni (per la pagina apposita)
				context.commit('translation/UPDATE_ALLTRANSLATIONS', responseSetup.data.AllTranslations)
				// carica l'elenco lingue disponibili
				context.commit('translation/UPDATE_LANGUAGES', responseSetup.data.Languages)
				context.commit('translation/UPDATE_LANGUAGES_TABLE', responseSetup.data.LanguagesTable)
				// carica la lista delle aziende
				context.commit('SET_AZIENDE', responseSetup.data.Aziende)
				// carica i setup utente (debugmode, temi, tipologiacampi, ecc)
				actions.initUserSetup(context)
				// avvia il keepalive
				var keepAliveIntervalId = setInterval(() => {
					actions.keepAlive(context)
				}, 10000)
				// carica le statistiche
				var annoCorrente = parseInt(moment().format('YYYY'))
				actions.loadStat(context, annoCorrente)
				// tutto a posto completiamo il login e gli passo l'oggetto interval del keepalive per il successivo reset in fase di logout
				context.commit('LOGIN', keepAliveIntervalId)
				// forza la route per il caricamento della dashboard (a meno che l'utente non sia un guest nel qual caso manda alla route apposita)
				router.push({ name: isGuest ? 'persona' : 'home' })
				resolve()
			}).catch(error => {
				context.commit('INIT_FAILED')
				console.error('error in get/setup/currentValues\n', error)
				reject(error)
			})
		})
	},
	// logout
	logout(context) {
		console.warn('logout!')
		actions.genericApiPost(context, {apiUrl: '/token/logout'}).then(() => {
			router.replace({ name: 'login' })
			context.commit('LOGOUT')
		}).catch(error => {
			console.error('errore durante il logout !!!\n', error)
		})
	},
	// cambio azienda corrente
	// in pratica prima passa l'informazione all'api che aggiorna la session utente e poi ripete il processo di init
	cambiaAziendaCorrente(context, idAzienda) {
		router.push({ name: 'wait' })
		context.commit('CAMBIO_AZIENDA')
		actions.genericApiPost(context, {apiUrl: '/setup/cambioAzienda', value: {Id: idAzienda}}).then(() => {
			actions.init(context)
		}).catch(error => {
			console.error('errore durante il cambio di azienda corrente !!!\n', error)
		})
	},
	// -----------------------------------------------------------------
	// --------- CHIAMATE API GENERICHE --------------------------------
	// -----------------------------------------------------------------
	// GET generica (accetta data.apiUrl per l'indirizzo da chiamare ed espone il risultato come promise)
	genericApiGet(context, apiUrl) {
		return new Promise((resolve, reject) => {
			Vue.axios.get(apiUrl).then((response) => {
				console.debug('get ' + apiUrl, response)
				resolve(response.data)
			}).catch((error) => {
				var humanizedError = 'Errore generico durante la get'
				if (error.response && error.response.data) {
					if(error.response.data.InnerException){
						if(error.response.data.InnerException.InnerException) {
							humanizedError = error.response.data.InnerException.InnerException.ExceptionMessage
						} else {
							humanizedError = error.response.data.InnerException.ExceptionMessage
						}
					} else {
						if(error.response.data.ExceptionMessage) {
							humanizedError = error.response.data.ExceptionMessage
						} else {
							humanizedError = error.response.data.Message
						}
					}
				}
				console.error('error in get ' + apiUrl, humanizedError, error)
				reject(humanizedError)
			})
		})
	},
	// POST generica (accetta data.apiUrl per l'indirizzo da chiamare e data.value per i dati da postare, espone il risultato come promise)
	genericApiPost(context, data) {
		return new Promise((resolve, reject) => {
			Vue.axios.post(data.apiUrl, data.value).then((response) => {
				console.debug('get ' + data.apiUrl, response)
				resolve(response.data)
			}).catch((error) => {
				var humanizedError = ''
				if (error.response && error.response.data) {
					if(error.response.data.ExceptionMessage){
						humanizedError = error.response.data.ExceptionMessage
						if(error.response.data.InnerException){
							humanizedError += '\n' + error.response.data.InnerException.ExceptionMessage
							if(error.response.data.InnerException.InnerException) {
								humanizedError += '\n' + error.response.data.InnerException.InnerException.ExceptionMessage
							}
						} 
					} else if(error.response.data.Message) {
						humanizedError = error.response.data.Message
					}
				} else {
					humanizedError = 'Errore generico durante la post'
				}
				console.error('error in post ' + data.apiUrl, humanizedError, error)
				reject(humanizedError)
			})
		})
	},
	// .................................................................
	// ......... UTILITY ...............................................
	// .................................................................
	// restituisce un intero negativo crescente (nell'ambito della sessione vuex)
	// viene utilizzato per creare gli id temporanei dei record aggiuntivi da inviare a .net dove il negativo viene comuque considerato zero e aggioranto
	getNegativeIncrementId(context) {
		context.commit('INCREMENT_NEGATIVE_ID')
		return state.negativeIncrementalId
	},
	// commuta il tema corrente
	// NB di fatto si limita a salvare il dato ricevuto perché ad oggi non sono ancora riuscito a modificare le impostazioni di vuetify da vuex
	toggleTheme(context, darkMode) {
		// salva il tema
		var ukv = {
			UserId: context.state.user.Codice,
			Key: 'theme.dark',
			Value: darkMode
		}
		actions.setUserKeyvalue(context, ukv)
	},
	// commuta la visualizza gli elementi di debug 
	toggleDebugElements(context) {
		context.commit('TOGGLE_DEBUG_ELEMENTS')
		var ukv = {
			UserId: context.state.user.Codice,
			Key: 'debugmode.on',
			Value: context.state.viewDebugElements
		}
		actions.setUserKeyvalue(context, ukv)
	},
	// attiva/disattiva la visualizza di un identificativo visivo per gli elementi traducibili
	toggleShowTranslatableElements(context) {
		context.commit('TOGGLE_SHOW_TRANSLATABLE_ELEMENTS')
	},
	// attiva/disattiva la visualizza della sezione/chiave per gli elementi traducibili
	toggleShowKeyTranslatableElements(context) {
		context.commit('TOGGLE_SHOW_KEY_TRANSLATABLE_ELEMENTS')
	},
	// imposta aspetto grafico campi di edit
	setTipologiaCampiEdit(context, value) {
		context.commit('SET_TIPOLOGIA_CAMPI_EDIT', value)
		var ukv = {
			UserId: context.state.user.Codice,
			Key: 'tipologiaCampiEdit',
			Value: JSON.stringify(value)
		}
		actions.setUserKeyvalue(context, ukv)
	},
	// inizializza da keyvalues l'aspetto dei campi di edit
	initTipologiaCampiEdit(context) {
		if(state.keyvalues['tipologiaCampiEdit']) {
			var value = JSON.parse(state.keyvalues['tipologiaCampiEdit'])
			if(value) context.commit('SET_TIPOLOGIA_CAMPI_EDIT', value)
		}
	},
	// commuta la visualizzazione compatta (dense)
	toggleCompactVisualization(context) {
		context.commit('TOGGLE_COMPACT_VISUALIZATION')
		var ukv = {
			UserId: context.state.user.Codice,
			Key: 'compactVisualization.on',
			Value: context.state.compactVisualization
		}
		actions.setUserKeyvalue(context, ukv)
	},
	// inizializza da keyvalues la visualizzazione compatta (dense)
	initCompactVisualization(context) {
		if(state.keyvalues['compactVisualization.on']) {
			var loadedValue = state.keyvalues['compactVisualization.on'] === 'true'
			var currentValue = state.compactVisualization
			if(loadedValue !== currentValue) context.commit('TOGGLE_COMPACT_VISUALIZATION')
		}
	},
	// commuta la modalita' di apertura del cellulare tra telefono e whatsapp
	toggleCellularOpenMode(context) {
		context.commit('TOGGLE_CELLULAR_OPEN_MODE')
		var ukv = {
			UserId: context.state.user.Codice,
			Key: 'cellularOpenMode.whatsapp',
			Value: context.state.cellularOpenMode
		}
		actions.setUserKeyvalue(context, ukv)
	},
	// inizializza da keyvalues la modalita' di apertura del cellulare tra telefono e whatsapp
	initCellularOpenMode(context) {
		if(state.keyvalues['cellularOpenMode.whatsapp']) {
			var loadedValue = state.keyvalues['cellularOpenMode.whatsapp'] === 'true'
			var currentValue = state.cellularOpenMode
			if(loadedValue !== currentValue) context.commit('TOGGLE_CELLULAR_OPEN_MODE')
		}
	},
	// commuta la modalita' di uscita dalla pagina persona
	togglePersoneExitAfterSave(context) {
		context.commit('TOGGLE_PERSONE_EXIT_AFTER_SAVE')
		var ukv = {
			UserId: context.state.user.Codice,
			Key: 'personeExitAfterSave',
			Value: context.state.personeExitAfterSave
		}
		actions.setUserKeyvalue(context, ukv)
	},
	// inizializza da keyvalues la modalita' di uscita alla pagina persona
	initPersoneExitAfterSave(context) {
		if(state.keyvalues['personeExitAfterSave']) {
			var loadedValue = state.keyvalues['personeExitAfterSave'] === 'true'
			var currentValue = state.personeExitAfterSave
			if(loadedValue !== currentValue) context.commit('TOGGLE_PERSONE_EXIT_AFTER_SAVE')
		}
	},
	// inizializza i setup utente da keyvalues
	initUserSetup(context) {
		actions.initTipologiaCampiEdit(context)	
		actions.initCellularOpenMode(context)
		actions.initCompactVisualization(context)
		actions.initPersoneExitAfterSave(context) 
		// todo: debug mode (attualmente impostato nella master)
		// todo: tema (attualmente impostato nella master)
	},
	// .................................................................
	// ......... GESTIONE SETUP KEY VALUES USER ........................
	// .................................................................
	// carica keyvalue per l'utente corrente
	loadUserKeyvalues(context) {
		return actions.genericApiPost(context, {apiUrl: '/setup/keyvalue/current'})
	},
	// salva una keyvalue per l'utente corrente
	setUserKeyvalue(context, ukv) {
		return new Promise((resolve) => {
			actions.genericApiPost(context, {apiUrl: '/setup/keyvalue/current/set', value: ukv}).then((response) => {
				// se la chiave salvata è per l'utente corrente allora ricarico le sue chiavi
				if(ukv.UserId === state.user.Codice) {
					actions.loadUserKeyvalues(context).then((response) => {
						context.commit('SET_KEYVALUES', response)
						resolve(response)
					})
				} else {
					resolve(response)
				}
			})
		})
	},
	// .................................................................
	// ......... GESTIONE DECODIFICHE ..................................
	// .................................................................
	// un po un barbatrucco ma comunque funziona... 
	// questo metodo viene chiamato al login, prende i dati dalla tabella delle decodifiche
	// e crea una struttura adatta a bindare una tendina di selezione
	// una struttura più lineare e adatta al binding con il  
	decodificaLivelliCompetenzaPerBind(context) {
		var strutturaPerBind = []
		for(var ii=0; ii<5; ii++){
			strutturaPerBind.push({
				Codice: ii,
				Label: actions.decodificaEtichettaLivelloCompetenza(context, ii),
				Color: actions.decodificaColoreLivelloCompetenza(context, ii)
			})
		}
		context.commit('SET_DECODIFICHE_LIV_COMP_BIND', strutturaPerBind)
	},
	decodificaEtichettaLivelloCompetenza(context, livello) {
		if(context.state.decodifiche && context.state.decodifiche.LivelliCompetenza) {
			return context.state.decodifiche.LivelliCompetenza['Livello'+livello]
		} 
		return null
	},
	decodificaColoreLivelloCompetenza(context, livello) {
		if(context.state.decodifiche && context.state.decodifiche.LivelliCompetenza) {
			return context.state.decodifiche.LivelliCompetenza['Colore'+livello]
		} 
		return null
	},
	modifyDecodifica(context, item) {
		context.commit('MODIFY_DECODIFICA_ITEM', item)
	},
	
	// .................................................................
	// ......... DASHBOARD .............................................
	// .................................................................
	// carica le statistiche sulle persone e il calendario dei corsi
	loadStat(context, annoRiferimento) {
		context.commit('SET_STAT', {})
		Vue.nextTick().then(() => {
			actions.genericApiGet(context, 'setup/stat').then(data => {
				context.commit('SET_STAT', data)
			})
		})
		context.commit('SET_CALENDARIO_CORSI', [])
		Vue.nextTick().then(() => {
			actions.genericApiPost(context, {apiUrl: 'Formazione/Corsi/Calendario', value: {Anno: annoRiferimento}}).then(data => {
				context.commit('SET_CALENDARIO_CORSI', data)
			})
		})
	},
	// .................................................................
	// ......... GESTIONE PERSONE ......................................
	// .................................................................
	// verifica se una persona e' stata modifcata da un altro utente
	checkUpdatedPersona(context, persona) {
		return new Promise((resolve) => {
			actions.genericApiPost(context, {apiUrl: 'RisorseUmane/Persone/TestIfUpdated', value: {Id: persona.Id, RowVersion: persona.RowVersion }}).then((isUpdated)=>{
				resolve(isUpdated)
			})
		})
	},
	// avvia edit di una persona
	editPersona(context, persona) {
		return new Promise((resolve) => {
			context.commit('EDIT_PERSONA', persona)
			// passa alla sottopagina di edit
			router.push({name: 'persona'})
			resolve()
		})
	},
	// aggiunge una nuova persona e poi passa alla pagina di edit
	addPersona(context) {
		return new Promise((resolve) => {
			actions.genericApiPost(context, {apiUrl: 'RisorseUmane/Persone/New'}).then((persona)=>{
				context.commit('EDIT_PERSONA', persona)
				// passa alla sottopagina di edit
				router.push({name: 'persona'})
				resolve()
			})
		})
	},
	// eliminazione persona
	deletePersona(context, persona) {
		actions.genericApiPost(context, {apiUrl: 'RisorseUmane/Persone/Elimina', value: {Id: persona.Id}}).then((persone)=>{
			context.commit('SET_PERSONE', persone)
		})
	},
	// eliminazione persona
	undeletePersona(context, persona) {
		actions.genericApiPost(context, {apiUrl: 'RisorseUmane/Persone/Recover', value: {Id: persona.Id}}).then((persone)=>{
			context.commit('SET_PERSONE', persone)
		})
	},
	// ricarica la lista persone
	refreshPersone(context, loadDeleted) {
		return new Promise((resolve) => {
			return actions.genericApiPost(context, {apiUrl: 'RisorseUmane/Persone/List', value: {'LoadDeleted':loadDeleted}}).then((persone)=>{
				context.commit('SET_PERSONE', persone)
				resolve()
			})
		})
	},
	// salvataggio persona (se ok ricarica la lista)
	savePersona(context, persona) {
		return new Promise((resolve, reject) => {
			return actions.genericApiPost(context, {apiUrl: 'RisorseUmane/Persone/Save', value: persona}).then((persone)=>{
				context.commit('SET_PERSONE', persone)
				// recupera dalla lista la persona appena salvata, sfruttando il flag lastSaved aggiunto dal server (#998)
				var personaSalvata = persone.find( x => { return x.LastModified })
				resolve(personaSalvata)
			}).catch(error => {
				console.error('savePersona error', error)
				reject(error)
			})
		})
	},
	// download allegato
	downloadAllegato(context, value) {
		return new Promise((resolve, reject) => {
			Vue.axios({method: 'POST', url: '/RisorseUmane/Persone/Allegato', data: value, responseType: 'blob'}).then((response) => {
				let fileName = response.headers['content-disposition'].replace('attachment; filename=', '').replace(/"/g, '')
				var contentType = response.headers['content-type']
				let blob = new Blob([response.data], { type: contentType })
				// crea il link farlocco per aprire il doc
				let link = document.createElement('a')
				document.body.appendChild(link) // fix per firefox
				link.setAttribute('type', 'hidden') // fix per firefox
				link.href = window.URL.createObjectURL(blob)
				link.download = fileName
				link.click()
				resolve(response.data)
			}).catch(error => {
				console.error('downloadAllegato error', error)
				reject(error)
			})
		})
	},	
	// apertura allegato
	openAllegato(context, value) {
		return new Promise((resolve, reject) => {
			Vue.axios({method: 'POST', url: '/RisorseUmane/Persone/Allegato', data: value, responseType: 'blob'}).then((response) => {
				// let fileName = response.headers['content-disposition'].replace('attachment; filename=', '').replace(/"/g, '')
				var contentType = response.headers['content-type']
				let blob = new Blob([response.data], { type: contentType })
				// crea il link farlocco per aprire il doc
				let link = document.createElement('a')
				document.body.appendChild(link) // fix per firefox
				link.setAttribute('type', 'hidden') // fix per firefox
				link.href = window.URL.createObjectURL(blob)
				link.target = '_blank'
				// link.download = fileName
				link.click()
				resolve(response.data)
			}).catch(error => {
				console.error('openAllegato error', error)
				reject(error)
			})
		})
	},	
	// carica un nuovo allegato 
	// l'api restituisce una struttura PErsonaAllegato e lasica il file parcheggiato nella temp (verrà spostato al salvataggio)
	uploadAllegato(context, formData) {
		return new Promise((resolve, reject) => {
			Vue.axios({method: 'POST', url: '/RisorseUmane/Persone/Allegato/Save', data: formData, headers: { 'Content-Type': 'multipart/form-data'}}).then((response) => {
				resolve(response.data)
			}).catch(error => {
				console.error('uploadAllegato error', error)
				reject(error)
			})
		})
	},
	// carica un foto per la persona
	// l'api sposta l'immagine nella cartella apposita e poi ne restituisce il path con lo stesso formalismo della vecchia UI
	uploadFoto(context, formData) {
		return new Promise((resolve, reject) => {
			Vue.axios({method: 'POST', url: '/RisorseUmane/Persone/Foto/Save', data: formData, headers: { 'Content-Type': 'multipart/form-data'}}).then((response) => {
				resolve(response.data)
			}).catch(error => {
				console.error('uploadFoto error', error)
				reject(error)
			})
		})
	},
	// funzione speciale chiamata dalla pagina di admin / utenti gruppi per aggiornare i gruppi correnti (metti che nell'admin viene fatta una modifica ad un gruppo così si riflette subito anche per le persone)
	updateGruppi(context, gruppi) {
		context.commit('SET_GRUPPIUTENTI', gruppi)
	},
	// .................................................................
	// ......... GESTIONE COMPETENZE ...................................
	// .................................................................
	// salva / aggiorna una competenza
	salvaCompetenza(context, competenza) {
		return actions.genericApiPost(context, {apiUrl: 'RisorseUmane/Competenze/Save', value: competenza}).then((competenze)=>{
			context.commit('SET_COMPETENZE', competenze)
		})
	},
	// refresh della lista
	refreshCompetenze(context) {
		return actions.genericApiPost(context, {apiUrl: 'RisorseUmane/Competenze/List'}).then((competenze)=>{
			context.commit('SET_COMPETENZE', competenze)
		})
	},
	// eliminazione Competenza
	deleteCompetenza(context, competenza) {
		actions.genericApiPost(context, {apiUrl: 'RisorseUmane/Competenze/Delete', value: {Id: competenza.Id}}).then((competenze)=>{
			context.commit('SET_COMPETENZE', competenze)
		})
	},
	// ripristino Competenza
	undeleteCompetenza(context, competenza) {
		actions.genericApiPost(context, {apiUrl: 'RisorseUmane/Competenze/Recover', value: {Id: competenza.Id}}).then((competenze)=>{
			context.commit('SET_COMPETENZE', competenze)
		})
	},	
	// .................................................................
	// ......... GESTIONE JOBS .........................................
	// .................................................................
	// salva / aggiorna una competenza
	salvaJob(context, job) {
		return actions.genericApiPost(context, {apiUrl: 'RisorseUmane/Jobs/Save', value: job}).then(jobs=>{
			context.commit('UPDATE_JOBS', jobs)
		})
	},
	// refresh della lista
	refreshJobs(context) {
		return actions.genericApiPost(context, {apiUrl: 'RisorseUmane/Jobs/List'}).then(jobs=>{
			context.commit('UPDATE_JOBS', jobs)
		})
	},
	updateJobLink(context, item) {
		return actions.genericApiPost(context, {apiUrl: 'RisorseUmane/Jobs/LinkCompetenza', value: item}).then(jobsCompetenze=>{
			context.commit('UPDATE_JOBS_COMPETENZE', jobsCompetenze)
		})
	},
	// .................................................................
	// ......... GESTIONE AUDITS .........................................
	// .................................................................
	// salva / aggiorna un audit
	saveAudit(context, audit) {
		return actions.genericApiPost(context, {apiUrl: 'RisorseUmane/Audits/Save', value: audit}).then(audits=>{
			context.commit('SET_AUDITS', audits)
		})
	},
	// refresh della lista
	refreshAudits(context) {
		return actions.genericApiPost(context, {apiUrl: 'RisorseUmane/Audits/List'}).then(audits=>{
			context.commit('SET_AUDITS', audits)
		})
	},
	// .................................................................
	// ......... GESTIONE VALUTAZIONI .........................................
	// .................................................................
	// salva / aggiorna un audit
	saveValutazione(context, valutazione) {
		return actions.genericApiPost(context, {apiUrl: 'RisorseUmane/Valutazioni/Save', value: valutazione}).then(valutazioni=>{
			context.commit('SET_VALUTAZIONI', valutazioni)
		})
	},
	// refresh della lista
	refreshValutazioni(context) {
		return actions.genericApiPost(context, {apiUrl: 'RisorseUmane/Valutazioni/List'}).then(valutazioni=>{
			context.commit('SET_VALUTAZIONI', valutazioni)
		})
	},
}

// ------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
import TranslationModule from './translation-module'
export default new Vuex.Store({
	namespaced: true,
	strict: true,
	state: state,
	getters: getters,
	mutations: mutations,
	actions: actions,
	modules: {
		translation: TranslationModule,
	}
})
