import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import vuetify from './plugins/vuetify'
import './plugins/axios'
import Gravatar from 'vue-gravatar'
import VueSwal from 'vue-swal'
import { sync } from 'vuex-router-sync'
import moment from 'moment'
import wb from './registerServiceWorker'
import 'leaflet/dist/leaflet.css'
import VueApexCharts from 'vue-apexcharts'
sync(store, router)

// per l'autoupdate della pwa
Vue.prototype.$workbox = wb

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component('v-gravatar', Gravatar)
Vue.use(VueSwal)

Vue.config.productionTip = false

// ***********************************************
// *** FILTRI GLOBALI ****************************
// ***********************************************
// Attenzione: non usare più i filtri se c'e' di mezzo qualcosa di localizzabile come date o valuta

Vue.filter('capitalize', function (value) {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.filter('fileSize', function (size) {
	if (isNaN(size)) return '-'
	if (size === 0) return '-'
	if (size < 1024) return size + ' Bytes'
	size /= 1024
	if (size < 1024) return size.toFixed(1) + ' Kb'
	size /= 1024
	if (size < 1024) return size.toFixed(1) + ' Mb'
	size /= 1024
	if (size < 1024) return size.toFixed(1) + ' Gb'
	size /= 1024
	return size.toFixed(2) + ' Tb'
})
Vue.filter('truncate', function (text, length) {
	return text.slice(0, length) + (length < text.length ? '...' : '')	
})


// ***********************************************
// *** METODI GLOBALI ****************************
// ***********************************************

Vue.mixin({
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		translations() { return this.$store.getters['translation/translations'] },
		viewTranslatableElements() { return this.$store.getters['viewTranslatableElements'] },
		viewKeyTranslatableElements() { return this.$store.getters['viewKeyTranslatableElements'] },
		currentLocaleShort() { 
			var cu = this.$store.getters['currentUser'] 
			if(cu){
				return cu.LinguaCorrenteShort
			}else {
				return 'it'
			}
		},
		currentLocale() { 
			var cu = this.$store.getters['currentUser'] 
			if(cu){
				return cu.LinguaCorrente
			}else {
				return 'it-IT'
			}
		}
	},
	methods: {
		// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		// 🔥 NUOVO METODO GLOBALE DI TRADUZIONE 🔥
		// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		//   text    - [obb] testo iniziale che viene utilizzato sia come default per la lingua base sia come chiave per il successivo retrive 
		//	 options - [opz] è un oggetto che può contenere le i seguenti campi
		//             section       - [opz] viene usato come sezione al posto della route corrente
		//			   sectionSuffix - [opz] viene aggiunto come suffisso alla section (ad esempio per distinguere diverse tab sotto una stessa route)
		//             key           - [opz] viene usato come chiave al posto del testo
		//             keyPrefix     - [opz] viene usato come prefisso davanti alla chiave (si parte dall'ipotesi che in questo caso la chiave venga autogenerata e quindi il parametro "key" non sia stato passato... ma nessuno lo vieta 😁 )
		//
		// note:     - l'uso di sectionSuffix è particolarmente utile all'interno dei controlli per far si che rispettino la stessa nomenclatura della pagina padre, vedi ad esempio in "persona" 
		// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		gTranslate(text, options) {
			if(text) { // se text non è valorizzato, non c'e' niente da fare
				if (!this.translations) {
					// translation non accessibile o non valorizzato, non facciamo nulla (per ora )
					return text + ' 🟥'
				}else {
					// ricava section dalle opzioni se fornite oppure dalla route
					var section = (options && options.section ? options.section : this.$route.name ? this.$route.name : this.$route.path).toLowerCase().replace(/ /g, '_')
					// aggiunto come suffisso alla section (ad esempio per distinguere diverse tab sotto una stessa route)
					if(options && options.sectionSuffix) {
						section = section + options.sectionSuffix
					}
					// ricava key dalle opzioni se fornite oppure dal testo
					var key = (options && options.res ? options.res : options && options.key ? options.key : text).toLowerCase().replace(/ /g, '_')
					// se keyPrefix è valorizzato lo usa appunto come prefisso davanti alla chiave (si parte dall'ipotesi che la chiave in questo caso sia autogenerata a partire dal testo, altrimenti non avrebbe molto senso)
					if(options && options.keyPrefix) {
						key = options.keyPrefix + key
					}
					// verifica se la sezione non esiste
					var sectionKeys = this.translations[section]
					if (sectionKeys) {
						// la sezione esiste, verifichiamo se esiste anche la traduzione risultato
						var translatedValue = sectionKeys[key]
						if(translatedValue) {
							if(this.viewKeyTranslatableElements) translatedValue = '['+ section+ '/' + key +']'
							if(this.viewTranslatableElements) translatedValue += '🟢'
							return translatedValue 
						}
					}
					// se siamo arrivati fino a qui vuol dire che manca la traduzione, chiamiamo quindi il solito metodo nello store ma nel frattempo (ricordo che siamo in un metodo sincrono!) facciamo uscire il testo originario con un marcatore colorato
					this.$store.dispatch('translation/translate', {section: section, key: key, value: text}).catch(error => {
						console.error('gTranslate() - fail! [' + section + '/' + key + ']\n', error)
					})
					return text + ' 🟩'
				}
			} else 
				return text
		},
		// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		// applica gTranslate alla testata di una tabella (itera gli elementi e traduce text)
		// usa gli stessi parametri della gTranslate normale 
		// attenzione che salvo override keyPrefix viene impostato a "col_"
		// todo: attualmente le colonne della tableHeader vengono gestite hardcode nel map, bisognerebbe invece automatizzare  
		// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		//   tableHeader -  [obb] testo iniziale che viene utilizzato sia come default per la lingua base sia come chiave per il successivo retrive 
		//	 options 	 -  [opz] è un oggetto che può contenere le i seguenti campi
		//                  section       - [opz] viene usato come sezione al posto della route corrente
		//			   		sectionSuffix - [opz] viene aggiunto come suffisso alla section (ad esempio per distinguere diverse tab sotto una stessa route)
		//             		key           - [opz] viene usato come chiave al posto del testo
		//             		keyPrefix     - [opz] viene usato come prefisso davanti alla chiave (si parte dall'ipotesi che in questo caso la chiave venga autogenerata e quindi il parametro "key" non sia stato passato... ma nessuno lo vieta 😁 )
		//
		// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		gTranslateTableHeader(tableHeader, options) {
			if(!options) {
				options = { keyPrefix: 'col_' }
			} else if (!options.keyPrefix) {
				options.keyPrefix = 'col_'
			}
			return tableHeader.map(x => { return { 
				text: x.text ? this.gTranslate(x.text, options) : x.text, // non traduce inutilmente i testi vuoti usati nelle colonne in corrispondenza dei pulsanti
				value: x.value, 
				views: x.views, 
				responsive: x.responsive, 
				groupable: x.groupable 
			}})
		},
		// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		// ex filtri (dipendenti dalla localizzazione) trasformati in metodi globali per poter accedere al currentLocale
		// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		gStandardDate(value) {
			moment.locale(this.currentLocaleShort)
			return value ? moment(value).format('DD MMMM YYYY') : ''
		},
		gStandardDateWithHour(value) {
			moment.locale(this.currentLocaleShort)
			return value ? moment(value).format('DD MMMM YYYY - HH:mm:ss') : ''
		},
		gStandardHour(value) {
			moment.locale(this.currentLocaleShort)
			return value ? moment(value).format('HH:mm:ss') : ''
		},
		gShortDate(value) {
			moment.locale(this.currentLocaleShort)
			return value ? moment(value).format('DD MMM YYYY') : ''
		},
		gMonthName(value) {
			moment.locale(this.currentLocaleShort)
			return value ? moment(value, 'M').format('MMMM') : ''
		},
		gToCurrency(value) {
			if (typeof value !== 'number') {
				return value
			}
			var formatter = new Intl.NumberFormat(this.currentLocale, {
				style: 'currency',
				currency: 'EUR',
				minimumFractionDigits: 0
			})
			return formatter.format(value)
		},
		// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		// rules - globalizzate per poter accedere alle traduzioni
		// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		ruleCampoObbligatorio(value) {
			return !!value || this.gTranslate('Campo obbligatorio!', {section: 'rules'})
		},
		ruleSelezionaValore(value) {
			return !!value || value !== 0 || this.gTranslate('È necessario selezionare almeno un valore!', {section: 'rules'})
		},
		// lo so, questa fa schifo, ma per ora non ho trovato altre soluzioni :-(
		ruleMin8Char(value) {
			return value.length >= 8 || (this.gTranslate('Numero caratteri minimo:', {section: 'rules'}) + ' 8')
		},
		ruleEmail(value) {
			const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return pattern.test(value) || this.gTranslate('E-mail non valida', {section: 'rules'})
		},
	},
})

// ***********************************************
// *** COMPONENTI GLOBALI ************************
// ***********************************************

// componente di traduzione testuale 
import TranslatedText from '@/components/translated-text'
Vue.component('translated-text', TranslatedText)

// ***********************************************
// ***********************************************
// ***********************************************
new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
