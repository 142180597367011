<template>
	<!-- eslint-disable-next-line vue/no-v-html -->
	<span class="translation-block" v-html="gTranslate(slotText, {section:section, key:res, sectionSuffix:sectionSuffix})"></span>
</template>

<script>
/**********************************************************************************************
**** BLOCCO DI TESTO TRADOTTO, V2 *************************************************************
***********************************************************************************************
* versione semplificata rispetto a quella originaria del b2b. Si appoggia direttamente al metodo globale gTranslate()
*/
export default {
	name: 'TranslatedText',
	props: {
		// 
		sectionSuffix: {
			type: String,
			default: () => {return ''}
		},
		// proprietà che forza la sezione (pagina) da cui prelevare la traduzione (normalmente avverrebbe in automatico)
		section: {
			type: String,
			default: () => {return ''}
		},
		// forza la chiave e ignora quindi il contenuto del componente (normalmente viene invece usato quello per definire la chiave)
		res: {
			type: String,
			default: () => {return ''}
		}
	},
	computed: {
		slotText() { return this.$slots.default[0].text }
	},
}
</script>

<style scoped>
</style>
