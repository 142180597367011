/*
* Modulo vuex riservato alle traduzioni dell'interfaccia
*/

export default {
	namespaced: true,
	state: {
		// lingue di sistema
		languages: [],
		languagesTable: [],
		translations: {},
    	allTranslations: {},
	},
	getters: {
		languages: state => state.languages,
		languagesTable: state => state.languagesTable,
		translations: state => state.translations,
    	allTranslations: state => state.allTranslations,
	},
	mutations: {
		// carica le lingua di sistema
		UPDATE_TRANSLATIONS(state, data) {
			state.translations = data
		},
		UPDATE_ALLTRANSLATIONS(state, data) {
			state.allTranslations = data
		},
		UPDATE_LANGUAGES(state, data) {
			state.languages = data
		},
		UPDATE_LANGUAGES_TABLE(state, data) {
			// Vue.set(state, 'languagesTable', data)
			state.languagesTable = data
		},
		// carica una singola traduzione (di solito in fase iniziale quando le traduzioni sono nuove)
		ADD_TRANS(state, tquery) {
			// prima controlla la section e se assente la crea
			if (!state.translations[tquery.section]) {
				state.translations[tquery.section] = {}
			}
			// poi aggiunge la chiave
			state.translations[tquery.section][tquery.key] = tquery.tvalue
		}
	},
	actions: {
		// * ---------------- METODI PER LE TRADUZIONI ----------------------------------------------------------
		/*
		il meccanismo di traduzione è molto semplice:
		prima cerca nel modulo tramite la doppia chiave "section" e "key", se trova il valore lo restituisce e tutto è ok
		se non lo trova lo richiede via axios e poi lo carica per le future richieste
		*/
		// questo metodo viene utilizzato dal metodo globale gTranslate e non più dal componente translated text (che passa a sua volta da gTranslate)
		translate(context, tquery) {
			return new Promise((resolve, reject) => {
				// console.debug('translate -> ', tquery)
				var section = context.getters['translations'][tquery.section]
				var generate = true
				if (section) {
					// sezione trovata, cerca la chiave
					var value = section[tquery.key]
					if (value) {
						// tutto ok!
						resolve(value)
						generate = false
					} else {
						// chiave non trovata (filtro le 'page-subtitle' solo perché non vengono quasi mai usate e quindi ammorbano il log per la fava)
						// ? e se invece di loggare così caricassimo tutte le chiavi non trovate in una struttura da poter visualizzare assieme ai log (potrebbe essere una chiave valore con, nel volore, il conteggio dei non trovati)
						if (tquery.key !== 'page-subtitle') console.warn('translate : chiave non trovata ', tquery)
					}
				} else {
					// sezione non trovata
					console.warn('translate : sezione non trovata ', tquery)
				}
				//
				if (generate) {
					axios.post('/setup/traduzioni/add', tquery).then(response => {
						console.debug('post/setup/traduzioni/add\n', response)
						// aggiunge la risorsa al modulo
						tquery.tvalue = response.data
						// aggiorna direttamente la struttura interna in modo che non vengano più fatte chiamate per questa chiave
						context.commit('ADD_TRANS', tquery)
						resolve(response.data)
					}).catch(function(error) {
						console.error('post/setup/traduzioni/add\n', error)
						reject(error)
					})
				}
				
			})
		},

	}
}