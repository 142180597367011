import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

import it from 'vuetify/es5/locale/it'
import en from 'vuetify/es5/locale/en'
import colors from 'vuetify/lib/util/colors'

export default new Vuetify({
	lang: {
		locales: { it, en },
		current: 'it',
	},
	theme: {
		dark: true,
		themes: {
			dark: {
				accent: colors.orange,
			},
			/*
			light: {
				primary: '#E1072B',
				accent: colors.orange,
			  },
			dark: {
				primary: '#E1072B',
				accent: colors.orange,
			},
			*/
		}
	},
	icons: {
		iconfont: 'mdiSvg'
	}
})
