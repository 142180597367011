<template>
	<div>
		<router-view />
		<v-snackbar v-model="updatePwa.updating" app top center :timeout="-1" class="updating-snack"><h2>UPDATING</h2></v-snackbar>
	</div>
</template>

<script>
export default {
	name: 'App',
	components: {},
	data: () => ({
		updatePwa: {
			snackBtnText: '',
			snackText: '',
			snackShow: false,
			updating: false,
		}
	}),
	created() {
		// test this.updatePwa.updating = true
		if (this.$workbox) {
			this.$workbox.addEventListener('waiting', () => {
				// this.showRefreshUI()
				// saltiamo la visualizzazione della finestrina di conferma ed effettuiamo immediatamente il refresh
				this.updatePwa.updating = true
				this.refreshPwa()
			})
		}
	},
	methods: {
		showRefreshUI() {
			// Display a snackbar inviting the user to refresh/reload the app due
			// to an app update being available.
			// The new service worker is installed, but not yet active.
			// Store the ServiceWorkerRegistration instance for later use.
			this.updatePwa.snackBtnText = 'Aggiorna'
			this.updatePwa.snackText = 'Nuova versione!'
			this.updatePwa.snackShow = true
		},
		async refreshPwa() {
			this.updatePwa.snackShow = false
			console.warn('REFRESH PWA')
			await this.$workbox.messageSW({ type: 'SKIP_WAITING' })
		}
	},
}
</script>

<style lang="less">

/*****************
 * STILI GLOBALI *
 *****************/

/* impsota il cursore corretto sui td (o sull'intero tr) di una table */
td.clickable, tr.clickable td {
	cursor: pointer;
}

/* usato per rendere le label simili a quelle integrate nei controlli vuetify */
.small-label {
	opacity: 0.75;
	font-size: 75%;
}

/* rende circolari gli avatar */
.img-circle {
	border-radius: 50%;
}

/* imposta il font per gli swal che stranamente non viene preso da vuetify */
.swal-modal {
	font-family: Roboto, sans-serif;
}

.v-input--checkbox.v-input--dense {
	padding: 0 !important;
	margin: 0 !important;
	.v-input__slot {
		padding: 0 !important;
		margin: 0 !important;
		margin-bottom: -10px !important;
	}
}

/* tabella vecchio stile con bordi sottili */
.legacyTable {
	width:100%;
	border-collapse: collapse;
	tr {
		td, th{
			border-bottom: solid 1px silver;
			border-right: solid 1px silver;
			padding:2px;
			padding-left:4px;
			padding-right:4px;
		}
		td:first-child, th:first-child {
			border-left: solid 1px silver;
		}
		th {
			border-top: solid 1px silver;
		}
	}
}
.theme--light .legacyTable { 
	tr {
		td, th{
			border-color: rgba(0, 0, 0, 0.12) !important;
		}
	}
}
.theme--dark .legacyTable { 
	tr {
		td, th{
			border-color: rgba(255, 255, 255, 0.12) !important;
		}
	}
}


/* nelle v-data-table evita che gli header con la freccia di ordinamento vadano a capo  */
th.sortable {
	white-space: nowrap;
}



.updating-snack{
	.v-snack__wrapper {
		background-color: red !important;
		padding: 0;
	}
	h2 {
		font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
		text-align: center;
		margin: 0;
		padding: 0;
	}
}

</style>