import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'

Vue.use(VueRouter)

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/login',
			name: 'login',
			component: () => import('@/views/Login.vue'),
			meta: { requiresAuth: false, guestAllowed: true },
		},
		{
			path: '/',
			// name: 'home',
			component: () => import('@/views/Master.vue'),
			meta: { requiresAuth: true, guestAllowed: true },
			children: [
				{
					path: '',
					name: 'home',
					component: () => import('@/views/Dashboard.vue'),
					meta: { requiresAuth: true, guestAllowed: false }
				},
				{
					path: '/persone',
					name: 'persone',
					component: () => import('@/views/Persone.vue'),
					meta: { requiresAuth: true, guestAllowed: false },
				},
				{
					path: '/persone/dettaglio',
					name: 'persona',
					component: () => import('@/views/Persona.vue'),
					meta: { requiresAuth: true, guestAllowed: true },
				},
				{
					path: '/competenze',
					name: 'competenze',
					component: () => import('@/views/Competenze.vue'),
					meta: { requiresAuth: true, guestAllowed: false }
				},
				{
					path: '/jobs',
					name: 'jobs',
					component: () => import('@/views/Jobs.vue'),
					meta: { requiresAuth: true, guestAllowed: false }
				},
				{
					path: '/audits',
					name: 'audits',
					component: () => import('@/views/Audits.vue'),
					meta: { requiresAuth: true, guestAllowed: false }
				},
				{
					path: '/formazione',
					name: 'formazione',
					component: () => import('@/views/Formazione.vue'),
					meta: { requiresAuth: true, guestAllowed: false }
				},
				{
					path: '/valutazioni',
					name: 'valutazioni',
					component: () => import('@/views/Valutazioni.vue'),
					meta: { requiresAuth: true, guestAllowed: false }
				},
				{
					path: '/statistiche',
					name: 'statistiche',
					component: () => import('@/views/Statistiche.vue'),
					meta: { requiresAuth: true, guestAllowed: false }
				},
				// -- ----- --------------------------------------------------------
				{
					path: '/dummy',
					name: 'dummy',
					component: () => import('@/views/Dummy.vue'),
					meta: { requiresAuth: true, guestAllowed: true }
				},
				// -- ----- --------------------------------------------------------
				{
					path: '/logout',
					name: 'logout',
					component: () => import('@/views/Logout.vue'),
					meta: { requiresAuth: true, guestAllowed: true }
				},
				{
					path: '/impostazioni/:tab',
					name: 'impostazioni',
					component: () => import('@/views/Impostazioni.vue'),
					meta: { requiresAuth: true, guestAllowed: true }
				},
				// -- admin --------------------------------------------------------
				{
					path: '/admin/colloqui',
					name: 'admin.colloqui',
					component: () => import('@/views/Admin/Colloqui.vue'),
					meta: { requiresAuth: true, onlyAdmin: true }
				},
				{
					path: '/admin/formazione',
					name: 'admin.formazione',
					component: () => import('@/views/Admin/Formazione.vue'),
					meta: { requiresAuth: true, onlyAdmin: true }
				},
				{
					path: '/admin/users',
					name: 'admin.users',
					component: () => import('@/views/Admin/Users.vue'),
					meta: { requiresAuth: true, onlyAdmin: true }
				},
				{
					path: '/admin/sitesetup',
					name: 'admin.sitesetup',
					component: () => import('@/views/Admin/Sitesetup.vue'),
					meta: { requiresAuth: true, onlyAdmin: true }
				},
				{
					path: '/admin/translations',
					name: 'admin.translations',
					component: () => import('@/views/Admin/Translations.vue'),
					meta: { requiresAuth: true, onlyAdmin: true }
				},
				{
					path: '/admin/log',
					name: 'admin.log',
					component: () => import('@/views/Admin/Log.vue'),
					meta: { requiresAuth: true, onlyAdmin: true }
				},
			]
		},
		{
			path: '/wait',
			name: 'wait',
			component: () => import('@/views/Wait.vue'),
			meta: { requiresAuth: true, guestAllowed: true }
		},		
	],
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	}
})

// verifica metatag e autorizzazioni
router.beforeEach((to, from, next) => {
	let isLoggedIn = store.getters['isLoggedIn']
	let isAdmin = store.getters['currentUser'].IsAdmin
	let isTrainingManager = store.getters['currentUser'].IsTrainingManager
	let isGuest = store.getters['currentUser'].IsGuest
	let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
	let onlyAdmin = to.matched.some(record => record.meta.onlyAdmin)
	// let guestAllowed = to.matched.some(record => record.meta.guestAllowed)
	let guestAllowed = to.matched[to.matched.length -1].meta.guestAllowed // serve per #96 - attenzione che controlla la specifica chiave della route, non è a cascata come gli altri con il .some
	// console.warn('beforeEach', to, 'isGuest: '+isGuest, 'guestAllowed: '+guestAllowed)
	if (requiresAuth && !isLoggedIn) { 
		next({ name: 'login' }) // utente non loggato che tenta di accedere ad una route protetta -> rimandato al login
	} else if (!requiresAuth && !isLoggedIn) { 
		next() // utente non loggato su route libera -> procede
	} else if (!guestAllowed && isGuest) { 
		next({ name: 'persona' }) // utente guest che tenta di accedere ad una route non permessa ai guest -> rimandato ala pagina guest
	} else if (onlyAdmin && (!isAdmin && !isTrainingManager)) { 
		next({ name: 'home' }) // utente non admin che tenta di accedere ad una route non permessa agli utenti normali -> rimandato alla home
	} else {
		next() // nessuna limitazione -> va alla route richiesta 
	}
})

export default router
